<!--
 * @Author: your name
 * @Date: 2021-12-04 16:09:05
 * @LastEditTime: 2021-12-23 15:46:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page1.vue
-->
<template>
  <div class="bz101-page1 pt80">
    <d-title model="ST-BZ101" ch="智能孢子捕获仪"></d-title>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/bz101/1.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.bz101-page1 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
  .img-div {
    height: calc(100% - 240px);
    margin-top: 103px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page1 {
    height: auto;
    position: relative;
    .img-div {
      width: 86%;
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
