<!--
 * @Author: your name
 * @Date: 2021-12-04 16:13:32
 * @LastEditTime: 2021-12-23 15:57:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page3.vue
-->

<template>
  <div class="bz101-page3 pt80">
    <div class="text-div">
      <ctitle titleb="植物病害检测的新利器"></ctitle>
      <div class="m-div">
        <p class="d-p" style="margin-top: 30px">
          智能孢子捕捉仪主要用于检测病害孢子存量及其扩散动态，为预测和预防病害流行、传染提供可靠数据。内含高倍显微镜，
        </p>
        <p class="d-p">
          采用了二维码识别追溯技术、精度限位技术、自动智能化聚焦技术、4G/5G无线传输控制技术等高科技手段，
        </p>
        <p class="d-p">
          增加精准定位功能，提高拍照清晰度。全天候实时采集分析，节省时间，增加自动模式操作，更加人性化。
        </p>
      </div>
    </div>
    <ul class="content-div">
      <li v-for="item in arr" :key="item.title">
        <span class="t-span din">{{ item.title }}</span>
        <span class="s-span" v-html="item.desc"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {
      arr: [
        {
          title: "2000万",
          desc: "高清摄像头",
        },
        {
          title: "800",
          desc: "高倍显微镜",
        },
        {
          title: "4G/5G",
          desc: "无线传输",
        },
        {
          title: "二维码",
          desc: "识别追溯技术",
        },
        {
          title: "多端查看",
          desc: "APP、小程序、WEB三端查看",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.bz101-page3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .d-p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      margin: 0;
    }
  }
  .content-div {
    display: flex;
    flex-wrap: wrap;
    width: 750px;
    li {
      width: 48%;
      display: flex;
      flex-direction: column;
      margin-top: 85px;
      .t-span {
        font-size: 60px;
        font-weight: bold;
        color: #1dbf73;
        line-height: 48px;
      }
      .s-span {
        margin-top: 20px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba($color: #fff, $alpha: 0.6);
        line-height: 36px;
      }
      &:nth-child(2n) {
        margin-left: 4%;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .bz101-page3 {
    .text-div {
      .d-p {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page3 {
    height: auto;
    position: relative;
    .text-div {
      width: 86%;
      display: flex;
      justify-content: center;
      line-height: 0.36rem;
      .m-div {
        margin-top: 20px;
        p {
          display: inline;
        }
        .d-p {
          font-size: 0.27rem;
          line-height: 0.36rem;
        }
      }
    }
    .content-div {
      width: 86%;
      li {
        margin-top: 0.73rem;
        .t-span {
          font-size: 0.55rem;
          line-height: 0.44rem;
        }
        .s-span {
          font-size: 0.22rem;
          line-height: 0.25rem;
        }
      }
    }
  }
}
</style>
