<!--
 * @Author: your name
 * @Date: 2021-12-04 16:25:02
 * @LastEditTime: 2021-12-23 16:13:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page6.vue
-->
<template>
  <div class="bz101-page6 pt80">
    <div class="t-div">
      <ctitle titleb="可靠又美观的监测好帮手"></ctitle>
      <p class="p-24 d-p">
        比起其他同类产品，智能孢子分析仪ST-BZ101的外形设计新颖、美观。该设备整体采用弧形设计，线条流畅，防止雨水进入壳体。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/bz101/2.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "bz101-page6",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.bz101-page6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  .t-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .d-p {
      width: 1008px;
      margin-top: 20px;
    }
  }
  .img-div {
    margin-top: 20px;
    height: calc(100% - 200px);
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page6 {
    height: auto;
    .t-div {
      .d-p {
        width: 86%;
        margin-top: 20px;
      }
    }
    .img-div {
      margin-top: 20px;
      width: 86%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
