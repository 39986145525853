<!--
 * @Author: your name
 * @Date: 2021-12-04 16:16:32
 * @LastEditTime: 2022-01-26 16:40:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page4.vue
-->
<template>
  <div class="bz101-page4 pt80">
    <div class="t-div">
      <ctitle class="w-title" titleb="数字化自动采集病原菌孢子图像"></ctitle>
      <p class="d-p p-24">
        内含800高倍显微镜，2000万高清摄像头，自动智能化聚焦技术；能够自动对所捕获病原菌孢子，所拍摄图像清晰度能够达到人工识别病菌孢子种类的要求。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/bz101/6.png"
        alt="上太科技"
      />

      <div class="i-desc desc-1">
        <span class="line-span"></span>
        <p class="line-label">800高倍显微镜</p>
      </div>
      <div class="i-desc desc-2">
        <span class="line-span"></span>
        <p class="line-label">2000万高清摄像头</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "bz101-page4",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bz101-page4 {
  position: relative;
  height: 100vh;
  ::v-deep {
    .big-p {
      white-space: nowrap;
    }
  }
  .t-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    .d-p {
      width: 1100px;
      margin-top: 20px;
    }
  }
  .img-div {
    margin: auto;
    // width: 100%;
    height: calc(100% - 240px);
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    // background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/cq101/2.png");
    background-size: cover;
    position: relative;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

.i-desc {
  position: absolute;
  top: 50%;
  overflow: hidden;
  width: 0;
  &.desc-1 {
    top: 55%;
    right: 53%;
  }
  &.desc-2 {
    top: 50%;
    left: 53%;
    text-align: right;
  }
  .line-span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 300px;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    position: relative;
    height: 0;
    // height: 16px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 22px;
    font-weight: bold;
    &.c-g {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page4 {
    overflow: hidden;
    .w-title {
      width: 60%;
      ::v-deep {
        white-space: normal;
      }
    }

    .t-div {
      .d-p {
        width: 86%;
        margin-top: 16px;
      }
    }

    .img-div {
      margin: auto;
      // width: 100%;
      width: 70%;
      margin-top: 0.36rem;
      transform: translateX(30%);
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .i-desc {
    width: 2.8rem;
    &.desc-2 {
      width: 3rem;
      top: 48%;
      right: 47%;
      left: unset;
      text-align: left;
    }
    .line-span {
      width: 100%;
    }
    p {
      margin-top: 3px;
      height: 0.2rem;
      font-size: 0.15rem;
      &.c-g {
        font-size: 0.16rem;
      }
    }
  }
}
</style>
