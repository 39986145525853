<!--
 * @Author: your name
 * @Date: 2021-12-04 16:12:21
 * @LastEditTime: 2021-12-23 15:51:14
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page2.vue
-->

<template>
  <div class="bz101-page2">
    <div class="text-div">
      <span>植物病害</span>
      <span>检测的新利器</span>
    </div>
    <div class="filter-div"></div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.bz101-page2 {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 2;
  overflow: hidden;
  .text-div {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-size: 100px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1dbf73;
    line-height: 120px;
  }
  .filter-div {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    // background-color: rgba($color: #161616, $alpha: 0.7);
    background-image: radial-gradient(
      rgba($color: #161616, $alpha: 0.7),
      rgba($color: #161616, $alpha: 1)
    );
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page2 {
    position: relative;
    height: auto;
    padding-top: 100px;
    .text-div {
      opacity: 1;
      position: relative;
      transform: scale(1);
      font-size: 0.73rem;
      line-height: 1.09rem;
    }
    .filter-div {
      opacity: 0;
    }
  }
}
</style>
