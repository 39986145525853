<!--
 * @Author: your name
 * @Date: 2021-12-04 16:21:25
 * @LastEditTime: 2021-12-23 16:12:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page5.vue
-->
<template>
  <div class="bz101-page5 pt80">
    <div class="title-div">
      <ctitle titleb="强大AI数据分析"></ctitle>
      <ctitle titleb="助力预防预测病害"></ctitle>
      <p class="p-24 d-p">
        通过放大分析该区域的孢子的种类及其数量，以图片格式上传至平台，用户通过平台监测到该区域的孢子情况；采用强大AI分析算法，实现对病菌孢子图片的智能化统计与分析，无需人工查看标注，缩短了预测预报周期。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/bz101/7.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "bz101-page5",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.bz101-page5 {
  display: flex;
  // flex-direction: column;
  align-items: center;
  padding: 0 11vw;
  padding-top: 80px;
  height: 100vh;

  ::v-deep {
    .big-p {
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
  }
  .img-div {
    margin-right: 50px;
    text-align: center;
    height: 80%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .d-p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1400px) {
  .bz101-page5 {
    .d-p {
      font-size: 20px;
    }
  }
  ::v-deep {
    .big-p {
      font-size: 52px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page5 {
    flex-direction: column;
    padding-top: 60px;
    height: auto;
    overflow: hidden;

    ::v-deep {
      .big-p {
        white-space: nowrap;
        font-size: 0.55rem;
        text-align: center;
      }
    }
    .img-div {
      margin-right: 0;
      text-align: center;
      width: 100%;
      margin-top: 20px;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .d-p {
      margin-top: 20px;
      font-size: 0.27rem;
      line-height: 0.44rem;
    }
  }
}
</style>
