<!--
 * @Author: your name
 * @Date: 2021-12-04 16:07:03
 * @LastEditTime: 2022-01-26 16:41:05
 * @LastEditors: Please set LastEditors
 * @Description: 孢子仪
 * @FilePath: \new-website-1123\src\views\special\bz101\index.vue
-->
<template>
  <div class="bz101-page">
    <div class="sticky-box1">
      <div class="sticky-child">
        <page1></page1>
        <page2></page2>
      </div>
    </div>
    <page3 class="opacity-page"></page3>
    <div class="sticky-box2 opacity-page">
      <div class="sticky-child">
        <page4></page4>
      </div>
    </div>
    <page5 class="opacity-page"></page5>
    <page6 class="opacity-page"></page6>
    <page7 class="opacity-page"></page7>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import page7 from "./page7.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "",
  components: {
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"04a259da6e8b425e9c33f9541c8be59c"})
    },
    /**
     * @description: 监听滚动
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page1s();
        this.page2s();
      }
    },
    page2s() {
      let page4 = document.querySelector(".bz101-page4");
      let box2 = document.querySelector(".sticky-box2");
      let ctop = this.scrollTop - box2.offsetTop;
      let textLeft = page4.querySelector(".desc-1");
      let textRight = page4.querySelector(".desc-2");
      let labelLeft = textLeft.querySelector(".line-label");
      let labelRight = textRight.querySelector(".line-label");

      if (ctop > 700) {
        let top = ctop - 700;
        let rate = Math.min(1, top / 300);
        labelRight.style.height = labelLeft.style.height = rate * 30 + "px";
        textLeft.style.width = textRight.style.width = 300 + "px";
      } else if (ctop > 200) {
        let top = ctop - 200;
        let rate = Math.min(1, top / 500);
        labelRight.style.height = labelLeft.style.height = 0;
        textLeft.style.width = textRight.style.width = rate * 300 + "px";
      } else if (ctop < 200) {
        textLeft.style.width = textRight.style.width = 0;
        labelRight.style.height = labelLeft.style.height = 0;
      }
    },
    page1s() {
      let page1 = document.querySelector(".bz101-page1");
      let page2 = document.querySelector(".bz101-page2");
      let p1Title = page1.querySelector(".d-title-box");
      let p2Filter = page2.querySelector(".filter-div");
      let p2Text = page2.querySelector(".text-div");

      if (this.scrollTop > 1200) {
        let top = this.scrollTop - 1200;
        let rate = top / 500;
        p2Filter.style.opacity = 1;
        let scale = Math.max(1, (1 - rate) * 4);
        p2Text.style.transform = `scale(${scale})`;
        p2Text.style.opacity = rate;
      } else if (this.scrollTop > 700) {
        p2Text.style.opacity = 0;
        let top = this.scrollTop - 700;
        let rate = top / 500;
        p2Filter.style.opacity = rate;
        p1Title.style.opacity = 0;
      } else if (this.scrollTop > 200) {
        let top = this.scrollTop - 200;
        let rate = top / 500;
        p1Title.style.opacity = 1 - rate;
      } else if (this.scrollTop < 200) {
        p1Title.style.opacity = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-child {
  position: sticky;
  width: 100%;
  top: 0;
  height: 100vh;
}
.sticky-box1 {
  height: calc(100vh + 1800px);
}
.sticky-box2 {
  height: calc(100vh + 1400px);
}

@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    height: auto;
  }
  .sticky-box1 {
    height: auto;
  }
  .sticky-box2 {
    height: auto;
  }
}
</style>
