<!--
 * @Author: your name
 * @Date: 2021-12-04 16:29:36
 * @LastEditTime: 2022-01-26 17:33:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\bz101\page7.vue
-->
<template>
  <div class="bz101-page7 pt80">
    <div class="t-div">
      <ctitle titleb="APP、小程序、WEB三端查看"></ctitle>
      <p class="p-24 d-p">
        支持APP、小程序、平台WEB三端多种查看方式。采用云服务器技术，实现管理人员远程对病菌孢子图片的统计与分析。支持远程控制设备，如开关机、远程自动拍照和手动拍照、设置采样时间、工作时段等。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/bz101/8.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bz101-page7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  padding-top: 180px;
  .t-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .d-p {
      max-width: 1120px;
      margin-top: 20px;
    }
  }
  .img-div {
    max-width: 1500px;
    width: 78%;
    margin-top: 20px;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 1400px) {
  .bz101-page7 {
    .d-p {
      font-size: 20px;
    }
  }
  ::v-deep {
    .big-p {
      font-size: 52px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .bz101-page7 {
    flex-direction: column;
    padding-top: 60px;
    height: auto;
    overflow: hidden;

    ::v-deep {
      .big-p {
        white-space: normal;
        font-size: 0.55rem;
        text-align: center;
        width: 86%;
      }
    }
    .img-div {
      margin-right: 0;
      text-align: center;
      margin-top: 20px;
      width: 86%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .d-p {
      width: 86%;
      margin-top: 20px;
      font-size: 0.27rem;
      line-height: 0.44rem;
    }
  }
}
</style>
